<template>
  <div v-if="!parseInt($store.state.user.uid)" class="page px-4 px-md-4 pt-4">
    <div class="d-flex px-0 px-md-4 pb-8 align-center justify-space-between">
      <!-- <div>
        <v-btn small color="white" class="text-capitalize px-6 caption-small rounded-xl" @click="activeMenu ='reward'">
          Rewards
        </v-btn>
        &nbsp;
        <v-btn small color="white" class="text-capitalize px-6 caption-small rounded-xl" @click="activeMenu ='points'">
          Points
        </v-btn>
      </div> -->
      <span class="font-weight-bold" v-if="$store.state.user.role !== 'pusat'">
        Rewards
      </span>
      <span class="font-weight-bold" v-if="$store.state.user.role === 'pusat'">
        Rewards Management
      </span>
      <span class="caption-small">
        Home > Point & Rewards
      </span>
    </div>
    <v-row no-gutters>
      <v-col col="12" class="col-md-4 px-4">
        <v-btn v-if="$store.state.user.role === 'pusat'" depressed small block color="blue" class="text-capitalize" @click.prevent="OPEN_REWARD()">
          <v-icon class="mr-1 body-1 text-white">
            mdi-plus-circle-outline
          </v-icon>
          <span class="caption-small text-white">
            Add Reward
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters class="d-flex flex-column-reverse flex-md-row pt-8">
      <v-col cols="12" class="col-md-12">
        <v-row no-gutters>
          <v-col
            v-for="(reward, iReward) in rewards.data"
            :key="'reward-' + iReward"
            cols="12"
            class="col-md-4 px-2 px-md-4 mb-6"
          >
            <v-card class="text-center rounded-lg accent" :style="{ opacity: parseInt(reward.status) ? '1' : '.7' }">
              <div style="width: 100%;border-radius: 7px 7px 0px 0px;overflow: hidden;">
                <div class="cover-img" :style="{ paddingBottom: '75%', backgroundImage: 'url(' + (reward.image || '/images/no-image.jpg') + ')' }" />
              </div>
              <div class="px-2 pt-3">
                <v-icon class="body-2 mr-1" color="yellow" style="padding-bottom: 2px;">
                  mdi-gold
                </v-icon>
                {{ $formatMoney(parseInt(reward.poin), 0, '.', ',') }} Point
              </div>
              <div class="subtitle-2 px-4 pb-0 pt-1">
                {{ reward.title }}
              </div>
              <div v-if="$store.state.user.role !== 'pusat'" class="px-8 pt-1 pb-5">
                <v-progress-linear
                  :buffer-value="countPoint(reward.poin)"
                  :value="countPoint(reward.poin)"
                  stream
                  height="10"
                  color="primary"
                  class="mt-2 rounded"
                >
                  <template v-slot:default="{ value }">
                    <span :class="parseInt(value) > 50 ? 'white--text' : ''" style="font-size: 10px;">{{ Math.ceil(value) }}%</span>
                  </template>
                </v-progress-linear>
              </div>
              <div v-if="$store.state.user.role === 'pusat'" class="px-3 pt-3 pb-6 primary--text">
                <v-btn depressed small dark color="blue" class="text-capitalize px-6 caption-small rounded-xl" @click.prevent="OPEN_REWARD(reward)">
                  Update Reward
                </v-btn>
              </div>
              <div v-else class="px-3 pb-6 primary--text">
                <v-btn v-if="countPoint(reward.poin) < 100" depressed small dark color="blue" class="text-capitalize px-6 caption-small rounded-xl" to="/product">
                  Collect More Point
                </v-btn>
                <v-btn v-else-if="parseInt(reward.user) === parseInt($store.state.user.id) && !parseInt(reward.allow_multiple)" depressed small dark color="blue" class="text-capitalize px-6 caption-small rounded-xl" disabled="disabled">
                  Reward Collected
                </v-btn>
                <v-btn v-else depressed small dark color="blue" class="text-capitalize px-6 caption-small rounded-xl" @click.prevent="RedeemPointShow(reward)">
                  Redeem Point
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col v-if="$store.state.user.role !== 'pusat'" cols="12" class="col-md-4 pb-8 pb-md-4 px-2 px-md-4">
        <div class="pa-4 accent rounded-lg">
          <div class="pb-4 text-center subtitle-2">
            History
          </div>
          <v-divider />
          <div v-if="!points.data.length" class="caption text-center">
            No history
          </div>
          <v-list v-if="points.data.length" three-line class="accent" style="max-height: 450px;overflow-y: auto;">
            <template v-for="(poin, iPoin) in points.data">
              <v-list-item
                v-if="poin.status !== 100"
                :key="'poin-' + iPoin"
                class="px-md-6"
              >
                <v-list-item-avatar>
                  <v-img :src="poin.user_image || '/images/no-image.jpg'" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="caption font-weight-bold my-0">
                    <span class="text-capitalize">{{ poin.user_role + ' ' + poin.user_name }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption my-0">
                    {{ poin.reward_title }} - {{ $formatMoney(parseInt(poin.poin), 0, '.', ',') }} point
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="poin.notes" class="caption-small">
                    {{ poin.notes }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="poin.notes_shipping" class="caption-small font-weight-bold">
                    {{ poin.notes_shipping }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="d-flex justify-end pt-2">
                    <div v-if="$store.state.user.role === 'pusat'">
                      <v-btn v-if="parseInt(poin.status) < 2" x-small depressed class="ml-1 red text-capitalize rounded-lg" @click.prevent="DeleteRewardPoin(poin)">
                        <v-icon color="white" class="caption">
                          mdi-close
                        </v-icon>
                      </v-btn>
                      <v-btn v-if="parseInt(poin.status) === 0" x-small depressed color="grey darken-4" dark class="ml-1 pr-1 text-capitalize rounded-xl" @click.prevent="ProcessRewardPoin(poin)">
                        Confirm
                        <v-icon dark class="caption">
                          mdi-menu-down
                        </v-icon>
                      </v-btn>
                      <v-btn v-else-if="parseInt(poin.status) === 1" x-small depressed color="orange darken-2" dark class="ml-1 pr-1 text-capitalize rounded-xl" @click.prevent="ProcessRewardPoin(poin)">
                        Ship Now
                        <v-icon dark class="caption">
                          mdi-menu-down
                        </v-icon>
                      </v-btn>
                      <v-btn v-else-if="parseInt(poin.status) === 2" x-small depressed color="blue darken-2" dark class="ml-1 pr-1 text-capitalize rounded-xl" @click.prevent="ProcessRewardPoin(poin)">
                        Set Complete
                        <v-icon dark class="caption">
                          mdi-menu-down
                        </v-icon>
                      </v-btn>
                      <v-btn v-else-if="parseInt(poin.status) > 2" disabled="disabled" x-small depressed color="green darken-2" dark class="ml-1 text-capitalize rounded-xl">
                        Complete
                      </v-btn>
                    </div>
                    <div v-else>
                      <v-btn v-if="parseInt(poin.status) === 0" x-small depressed color="gren darken-3" dark class="ml-1 text-capitalize rounded-xl">
                        Pending
                      </v-btn>
                      <v-btn v-else-if="parseInt(poin.status) === 1" x-small depressed color="yellow darken-2" dark class="ml-1 text-capitalize rounded-xl">
                        Processed
                      </v-btn>
                      <v-btn v-else-if="parseInt(poin.status) === 2" x-small depressed color="orange darken-2" dark class="ml-1 text-capitalize rounded-xl">
                        Shipping
                      </v-btn>
                      <v-btn v-else-if="parseInt(poin.status) > 2" x-small depressed color="green darken-2" dark class="ml-1 text-capitalize rounded-xl" style="cursor: default!important;">
                        Collected
                      </v-btn>
                    </div>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="d-flex justify-space-between pt-2">
                    <small class="mt-1 caption-small">{{ $GEN_ID({ id: poin.user_id, created: poin.user_created }) }}</small>
                    <small class="caption-small" style="padding-top: 4px;">{{ $localDT(poin.created, 'daydate') }}</small>
                  </v-list-item-subtitle>
                  <v-divider class="mt-4" />
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </div>
      </v-col> -->
    </v-row>
    <v-dialog
      v-model="newReward.show"
      max-width="450"
      scrollable
      light
    >
      <v-card v-if="newReward.data">
        <v-card-title
          class="caption justify-center py-3 subtitle-1"
        >
          {{ newReward.data.id ? 'Update Reward' : 'Create Reward' }}
          <v-btn
            icon
            color="red"
            class="text-capitalize"
            small
            style="position: absolute;right: 10px;top: 10px;"
            @click="newReward.show = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-0">
          <div class="px-0 pt-0 pb-0 image-galleries">
            <v-row no-gutters class="pt-4 px-8">
              <v-col cols="4">
                <v-img
                  :src="newReward.data.image || '/images/no-image.jpg'"
                  :lazy-src="newReward.data.image || '/images/no-image.jpg'"
                  aspect-ratio="1"
                  class="grey lighten-2 rounded-lg c-pointer"
                  @click.prevent="UPLOAD_IMAGE()"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      />
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
          </div>
          <div class="px-8 pt-8 pb-0">
            <v-select
              v-model="newReward.data.for_role"
              :items="[
                { value: 'distributor', name: 'Distributor' },
                { value: 'agen', name: 'Agen' },
                { value: 'reseller', name: 'Reseller' },
                { value: 'customer', name: 'Customer' }
              ]"
              item-text="name"
              item-value="value"
              :name="Math.random()"
              label="Reward Role"
              autocomplete="off"
              outlined
              dense
              hide-details
              class="mb-4"
            />
            <v-text-field
              v-model="newReward.data.title"
              label="Reward Title"
              :name="Math.random()"
              outlined
              dense
              hide-details
              autocomplete="off"
              class="mb-4"
            />
            <Currency
              v-model.number="newReward.data.poin"
              label="Reward Poin"
              prefix="-"
            />
            <v-textarea
              v-model="newReward.data.description"
              :name="Math.random()"
              autocomplete="disabled"
              rows="2"
              label="Reward Description"
              outlined
              dense
              hide-details
              class="mb-4"
            />
            <v-select
              v-model.number="newReward.data.allow_multiple"
              :items="[{ value: 0, name: 'No' }, { value: 1, name: 'Yes' }]"
              item-text="name"
              item-value="value"
              :name="Math.random()"
              label="Allow Multiple Redeem"
              autocomplete="off"
              outlined
              dense
              hide-details
              class="mb-4"
            />
            <v-select
              v-model.number="newReward.data.status"
              :items="[{ value: 1, name: 'Active' }, { value: 0, name: 'No Active' }]"
              item-text="name"
              item-value="value"
              :name="Math.random()"
              label="Status"
              autocomplete="off"
              outlined
              dense
              hide-details
              class="mb-4"
            />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-end px-8 pb-2">
          <v-btn
            v-if="parseInt(newReward.data.id)"
            text
            color="red"
            small
            class="text-capitalize"
            @click="REWARD_PROCESS(true)"
          >
            Delete
          </v-btn>
          <v-spacer />
          <v-btn
            depressed
            :color="newReward.data.id ? 'blue' : 'green'"
            dark
            small
            class="rounded-xl text-capitalize px-4"
            @click="REWARD_PROCESS()"
          >
            {{ newReward.data.id ? 'Update' : 'Create' }}
          </v-btn>
          <v-spacer v-if="!newReward.data.id" />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="redeemConfirm.show"
      max-width="450"
      scrollable
      light
    >
      <v-card v-if="redeemConfirm.data">
        <v-card-title
          class="caption justify-center py-3 subtitle-1"
        >
          - Redeem Confirmation -
          <v-btn
            icon
            color="red"
            class="text-capitalize"
            small
            style="position: absolute;right: 10px;top: 10px;"
            @click="redeemConfirm.show = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-0 pt-4 pb-4">
          <div class="subtitle text-center font-weight-medium py-2 pt-md-2 pb-md-4 px-4 px-md-8">
            You will redeem <strong>{{ $formatMoney(parseInt(redeemConfirm.data.poin), 0, '.', ',') }}</strong> points to <strong>"{{ redeemConfirm.title || '-' }}"</strong>.<br>Reward will be processed after validated<br>by eBright Skin admin !
          </div>
          <v-divider class="mt-2 mb-6 mx-16" />
          <div v-if="redeemConfirm.description" class="subtitle px-4 px-md-8">
            <div class="text-center px-0 px-md-4">{{ redeemConfirm.description }}</div>
          </div>
          <div class="subtitle px-4 px-md-8 pt-8">
            <v-textarea
              v-model="redeemConfirm.notes"
              :name="Math.random()"
              autocomplete="disabled"
              rows="2"
              label="Add some notes for admin :"
              outlined
              dense
              hide-details
              class="mb-4"
            />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-center pt-3 px-4 pb-3">
          <v-btn
            depressed
            color="orange darken-3"
            small
            class="text-capitalize"
            @click.prevent="RedeemPoint()"
          >
            Confirm Now
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const newReward = {
  id: null,
  title: '',
  for_role: 'agen',
  poin: 0,
  description: '',
  image: '',
  allow_multiple: 0,
  status: 1
}
export default {
  name: 'Reward',
  data: () => ({
    rewards: {
      total: 0,
      data: []
    },
    points: {
      total: 0,
      data: []
    },
    newReward: {
      show: false,
      data: null
    },
    redeemConfirm: {
      show: false,
      title: '',
      notes: '',
      description: '',
      data: null
    },
    activeMenu: 'reward'
  }),
  mounted () {
    this.Init()
  },
  methods: {
    async ProcessRewardPoin (rewardPoin) {
      let next = true
      let notesShipping = ''
      if (parseInt(rewardPoin.status) === 0) {
        await this.$store.dispatch('POPUP', {
          t: this,
          show: true,
          type: 'confirm',
          title: 'Confirm',
          text: 'Are you sure want to confirm and process this reward point?'
        }).then((res) => {
          if (!res.result) {
            next = false
          }
        })
      }
      if (parseInt(rewardPoin.status) === 1) {
        await this.$store.dispatch('POPUP', {
          t: this,
          show: true,
          type: 'confirm_value',
          title: 'Input shipping awb number, notes, etc',
          text: 'Are you sure want to confirm and process this reward point?'
        }).then((res) => {
          if (res.result) {
            notesShipping = res.value || ''
          } else {
            next = false
          }
        })
      }
      if (parseInt(rewardPoin.status) === 2) {
        await this.$store.dispatch('POPUP', {
          t: this,
          show: true,
          type: 'confirm',
          title: 'Confirm',
          text: 'Are you sure want to set to complete?'
        }).then((res) => {
          if (!res.result) {
            next = false
          }
        })
      }
      if (!next) {
        return true
      }
      this.$store.dispatch('POIN_UPDATE', Object.assign(rewardPoin, { status: (parseInt(rewardPoin.status) + 1), notes_shipping: notesShipping })).then((res) => {
        if (res.status) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Reward Point Updated' })
          this.loadPoint()
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    },
    async DeleteRewardPoin (rewardPoin) {
      await this.$store.dispatch('POPUP', {
        t: this,
        show: true,
        type: 'confirm',
        title: 'Confirm',
        text: 'Are you sure want to delete this reward point? The points will be returned as member points!'
      }).then((res) => {
        if (res.result) {
          this.$store.dispatch('POIN_DELETE', rewardPoin.id).then((res) => {
            if (res.status) {
              this.$store.commit('SNACKBAR', { show: true, text: 'Reward Point Deleted' })
              this.loadPoint()
            } else {
              this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
            }
          })
        }
      })
    },
    Init () {
      this.loadReward()
      this.loadPoint()
      this.$store.dispatch('POIN_LIMIT_GET')
    },
    loadReward () {
      const role = this.$store.state.user.role === 'agen' || this.$store.state.user.role === 'distributor' ? this.$store.state.user.role : ''
      this.$store.dispatch('REWARD_GET', '?role=' + (role || '')).then((res) => {
        this.rewards.data = res.data.data.data || []
        this.rewards.total = parseInt(res.data.data.total) || 0
      })
    },
    loadPoint (limit) {
      this.$store.dispatch('POIN_GET', '?limit=' + (limit || 7)).then((res) => {
        this.points.data = res.data.data.data || []
        this.points.total = parseInt(res.data.data.total) || 0
      })
    },
    countPoint (poin) {
      const percent = parseInt(this.$store.state.poin_limit) > parseInt(poin) ? 100 : (parseInt(this.$store.state.poin_limit) * 100) / parseInt(poin)
      return percent
    },
    OpenRewardById (rewardID) {
      this.$store.dispatch('REWARD_GET_ONE', rewardID).then((res) => {
        if (res.status) {
          this.OPEN_REWARD(res.data.data)
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    },
    OPEN_REWARD (reward) {
      if (reward) {
        this.newReward.data = Object.assign({}, reward)
      } else {
        this.newReward.data = Object.assign({}, newReward)
      }
      this.newReward.show = true
    },
    REWARD_PROCESS (isDelete) {
      const reward = Object.assign({}, this.newReward.data)
      if (!reward.image && !isDelete) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please upload image' })
        return false
      }
      if (!reward.title && !isDelete) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please input title' })
        return false
      }
      if (!isDelete && reward.for_role !== 'distributor' && reward.for_role !== 'agen' && reward.for_role !== 'reseller' && reward.for_role !== 'customer') {
        this.$store.commit('SNACKBAR', { show: true, text: 'Select member role' })
        return false
      }
      if (!isDelete && !parseInt(reward.poin)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Poin is empty' })
        return false
      }
      if (parseInt(reward.id)) {
        if (isDelete) {
          if (!confirm('Delete reward ?')) {
            return false
          }
          reward.status = 100
        }
        this.$store.dispatch('REWARD_UPDATE', reward).then((res) => {
          if (res.status) {
            this.Init()
            this.$store.commit('SNACKBAR', { show: true, text: 'Reward updated' })
            this.newReward.show = false
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      } else {
        this.$store.dispatch('REWARD_ADD', reward).then((res) => {
          if (res.status) {
            this.Init()
            this.$store.commit('SNACKBAR', { show: true, text: 'Reward created' })
            this.newReward.show = false
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      }
    },
    RedeemPointShow (reward) {
      this.redeemConfirm.title = reward.title
      this.redeemConfirm.description = reward.description
      this.redeemConfirm.notes = ''
      this.redeemConfirm.data = Object.assign({}, reward)
      this.redeemConfirm.show = true
    },
    RedeemPoint () {
      const reward = Object.assign({}, this.redeemConfirm.data)
      this.$store.dispatch('POIN_ADD', {
        reward: reward.id,
        notes: this.redeemConfirm.notes
      }).then((res) => {
        if (res.status) {
          this.redeemConfirm.show = false
          this.Init()
          this.$store.commit('SNACKBAR', { show: true, text: 'Redeem point created' })
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    },
    UPLOAD_IMAGE (index) {
      const f = document.getElementById('imageUploader')
      if (f) {
        f.remove()
      }
      const $ = this
      const el = document.createElement('input')
      el.setAttribute('id', 'imageUploader')
      el.setAttribute('type', 'file')
      el.setAttribute('class', 'file-uploader')
      el.setAttribute('accept', '.jpg,.jpeg,.png')
      el.addEventListener('change', function (e) {
        $.UPLOAD_HANDLER(e, index)
      })
      document.body.appendChild(el)
      el.click()
    },
    async UPLOAD_HANDLER (event, index) {
      /* eslint-disable */
      const $ = this
      await $.$imgtoBase64(event, async function (res) {
        if (res.status) {
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            size: 500
          }).then((resUpload) => {
            if (resUpload) {
              $.newReward.data.image = resUpload.data.data.secure_url
            } else {
              $.$store.commit('SNACKBAR', { show: true, text: 'Failed upload' })
            }
          })
        } else {
          res.data === 'file_size'
            ? $.$store.commit('SNACKBAR', { show: true, message: 'Ops, file image is too large. Max size (images < 5Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>
